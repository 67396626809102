var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-tab", {
    ref: "ctomissTab",
    attrs: {
      type: "horizon",
      inlineLabel: true,
      dense: true,
      height: _vm.tabHeight,
      tabItems: _vm.tabItems,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (tab) {
          return [
            _c(tab.component, {
              tag: "component",
              attrs: {
                popupParam: _vm.popupParam,
                cto: _vm.cto,
                imprTabInfo: _vm.imprTabInfo,
                contentHeight: _vm.contentHeight,
                isOld: _vm.isOld,
                disabled: _vm.disabled,
              },
              on: {
                "update:popupParam": function ($event) {
                  _vm.popupParam = $event
                },
                "update:popup-param": function ($event) {
                  _vm.popupParam = $event
                },
                "update:cto": function ($event) {
                  _vm.cto = $event
                },
                getDetail: _vm.getDetail,
                closePopup: _vm.closePopup,
              },
            }),
          ]
        },
      },
    ]),
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v
      },
      expression: "tab",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }