<template>
  <c-tab
    ref="ctomissTab"
    type="horizon"
    :inlineLabel="true"
    :dense="true"
    :height="tabHeight"
    :tabItems="tabItems"
    v-model="tab"
  >
    <template v-slot:default="tab">
      <component
        :is="tab.component"
        :popupParam.sync="popupParam"
        :cto.sync="cto"
        :imprTabInfo="imprTabInfo"
        :contentHeight="contentHeight"
        :isOld="isOld"
        :disabled="disabled"
        @getDetail="getDetail"
        @closePopup="closePopup"
      />
    </template>
  </c-tab>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'ctoDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopCtoId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      cto: {
        sopCtoId: '',
        plantCd: '',
        ctoDate: '',
        ctoTime: '',
        observeUserId: '',
        observeCount: '',
        mdmSopId: '',
        workArea: '',
        ctoCompleteFlag: 'N',
        improveContents: '',
        priorityContents: '',
        managerUserId: '',
        sopName: '',
        processName: '',
        regUserId: '',
        chgUserId: '',
        workCharacteristics: '',
        humanFactors: '',
        coreActionItems: '',
        activityModels: [],
        ctoChecklistModels: [],
        ctoImproveModels: [],
      },
      tab: 'info',
      editable: true,
      detailUrl: '',
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopCtoId)
    },
    disabled() {
      return this.cto.ctoCompleteFlag === 'Y'
    },
    tabItems() {
      let items = [{ name: 'info', icon: 'edit', label: '행동관찰 정보', component: () => import(`${'./ctoInfo.vue'}`), key: uid() }]
      if (this.isOld) {
        items.push({ name: 'impr', icon: 'construction', label: '개선관리', component: () => import(`${'@/pages/common/ibm/mobileImprTab.vue'}`), key: uid() })
      }
      return items;
    },
    tabHeight() {
      return String(this.contentHeight - 70);
    },
    imprTabInfo() {
      return {
        // Mobile용 Start
        // customCol에 대한 card title
        requestContentsCols: ['checklistName'],
        // readOnly용으로 card-detail 내용이 들어감
        customCols: [
          {
            key: uid(), // v-for key
            label: '비고', // grid에 대한 하나의 column label
            name: 'remarks' // grid에 대한 하나의 column name
          }
        ],
        // Mobile용 End
        key: this.popupParam.sopCtoId, // 
        ibmTaskTypeCd: 'ITT0000060',
        ibmTaskUnderTypeCd: 'ITTU000090',
        requestContents: this.cto.sopName + ' / ' + this.cto.processName,
        disabled: this.disabled,
        isOld: this.isOld,
        gridItem: {
          isFormat: true,
          listUrl: '',
          param: null,
          title: '체크리스트 항목별 개선목록',
          merge: [],
          data: this.$_.filter(this.cto.ctoChecklistModels, (result) => {
            return result.ibmTooltip
          }),
          columns: [
            {
              name: 'checklistName',
              field: 'checklistName',
              label: '체크리스트 항목',
              align: 'left',
              style: 'width:250px',
              sortable: false,
            },
            {
              name: "selectValueName",
              field: "selectValueName",
              label: "결과",
              style: 'width:100px',
              align: "center",
              sortable: false,
            },
          ],
          research: {},
        },
        gridOther: {
          title: '항목외 개선 목록', // 항목외 개선 목록
        },
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.cto.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopCtoId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.cto, _result.data);
        },);
      } else {
        this.$set(this.cto, 'ctoDate', this.$comm.getToday())
        this.$set(this.cto, 'ctoTime', '03:00')
        this.$set(this.cto, 'observeUserId', this.$store.getters.user.userId)
      }
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>
